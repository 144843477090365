import React from "react"
import SHA512 from "crypto-js/sha512"

import ToolLayout from "../../components/layout/tool"

export default class LPSignatureGenerator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nonce: "",
      secret: "",
      request: "",
      requestValid: false,
      requestTrim: "",
    }
  }

  render() {
    var hashedRequest = () => {
      return SHA512(this.state.requestTrim)
        .toString()
        .toUpperCase()
    }

    var finalRequest = () => {
      try {
        var obj = JSON.parse(this.state.request)
        obj["nonce"] = this.state.nonce
        obj["sign"] = hashedRequest()
        return JSON.stringify(obj, null, "\t")
      } catch (e) {}
    }

    var updateStateValue = val => {
      this.setState(val)
      computeRequestTrim({ ...this.state, ...val })
    }

    var computeRequestTrim = jsn => {
      console.log("jsn")
      console.log(jsn)
      if (!jsn.requestValid) {
        return
      }
      try {
        var obj = JSON.parse(jsn.request)
        delete obj["nonce"]
        delete obj["sign"]

        GetRequestParameters(obj)
        // var requestJson = JSON.parse(decodeURIComponent( GetRequestParameters(obj)))

        this.setState({
          requestTrim:
            base_string.toUpperCase() +
            "NONCE=" +
            jsn.nonce +
            "&" +
            "SECRET=" +
            jsn.secret,
        })
      } catch (e) {
        console.error(e)
      }
    }

    var updateRequest = ev => {
      console.log(ev.target.value)
      var request = ev.target.value
      this.setState({ request })
      try {
        JSON.parse(request)
        this.setState({
          request,
          requestValid: true,
        })
        computeRequestTrim({
          ...this.state,
          ...{ request, requestValid: true },
        })
      } catch (e) {
        this.setState({ requestValid: false })
      }
    }

    const DisplayField = ({ value, rows }) => {
      if (
        this.state.request === "" ||
        this.state.nonce === "" ||
        this.state.secret === "" ||
        !this.state.requestValid
      ) {
        return (
          <p className="text-muted">
            Please supply the required details first.
          </p>
        )
      }
      return (
        <textarea
          className="form-control"
          rows={rows || "4"}
          value={value}
          disabled
        ></textarea>
      )
    }

    return (
      <ToolLayout title="LiquidPay Signature Generator">
        <div className="headline">
          <h1 className="text-primary m-0">LiquidPay Signature Generator</h1>
        </div>
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 my-2 text-primary">Getting Started</h2>
            <p className="text-muted">
              Details listed below is required for generating signature
            </p>
            <code>
              <ul>
                <li>Request body (JSON)</li>
                <li>Nonce</li>
                <li>Secret</li>
              </ul>
            </code>
          </div>
          <div className="col-md-8">
            <form>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="text-dark" htmlFor="requestBody">
                    Request Body
                  </label>
                  <textarea
                    id="requestBody"
                    className={
                      this.state.request === ""
                        ? "form-control"
                        : this.state.requestValid
                        ? "form-control is-valid"
                        : "form-control is-invalid"
                    }
                    rows="5"
                    placeholder="Request body in JSON format"
                    onChange={updateRequest}
                    value={this.state.request}
                  />
                  <div className="invalid-feedback">
                    The request body must be in valid JSON format!
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-10">
                  <label htmlFor="nonce">Nonce</label>
                  <div className="input-group">
                    <input
                      id="nonce"
                      type="text"
                      className="form-control"
                      placeholder="Nonce"
                      onChange={t =>
                        updateStateValue({ nonce: t.target.value })
                      }
                      value={this.state.nonce}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="nonce-generate"
                        onClick={() =>
                          updateStateValue({
                            nonce: Math.round(new Date().getTime() / 1000),
                          })
                        }
                      >
                        Refresh Nonce
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="text-dark" htmlFor="secret">
                    Secret
                  </label>
                  <input
                    id="secret"
                    type="text"
                    className="form-control"
                    placeholder="Secret Value"
                    onChange={t => updateStateValue({ secret: t.target.value })}
                    value={this.state.secret}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 1</h2>
            <p className="text-muted">Concatenated String</p>
            <p className="small">Must follow sequence</p>
          </div>
          <div className="col-md-8">
            <DisplayField value={this.state.requestTrim} />
          </div>
        </div>

        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 2</h2>
            <p className="text-muted">Generate signature</p>
          </div>
          <div className="col-md-8">
            <DisplayField value={hashedRequest()} />
          </div>
        </div>

        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 3</h2>
            <p className="text-muted">Result</p>
          </div>
          <div className="col-md-8">
            <DisplayField rows="10" value={finalRequest()} />
          </div>
        </div>
      </ToolLayout>
    )
  }
}

//Pulling from existing application
//TO BE REWRITE
var base_string
function GetRequestParameters(jsonData) {
  //clean base_string
  base_string = ""

  //setting up base_string and return the request body object
  return JSON.stringify(
    Object.keys(jsonData)
      .sort()
      .reduce(function(r, k) {
        var currentValue = jsonData[k]

        if (
          currentValue === "" ||
          currentValue === null ||
          k.toUpperCase() === "NONCE" ||
          k.toUpperCase() === "SIGN" ||
          (typeof currentValue === "object" &&
            (currentValue || currentValue.length === 0))
        ) {
          //do nothing, just skip this pair
        } else if (
          typeof currentValue === "object" &&
          currentValue.length > 0
        ) {
          //there may be an issue if the request post an array of strings or numbers
          //such as ["abc","def"], [0,1,2] and so on

          var text = []
          for (var i = 0; i < currentValue.length; i++) {
            var valueData = currentValue[i]
            var jso = JSON.stringify(
              Object.keys(valueData)
                .sort()
                .reduce(function(n, m) {
                  var data = valueData[m]
                  if (typeof data === "boolean") {
                    let booleanNo = 0
                    if (data) {
                      booleanNo = 1
                    }
                    n[m] = data
                    base_string = base_string + m + "=" + booleanNo + "&"
                  } else {
                    if (typeof data === "string") {
                      //The escape function is a property of the global object. Special characters are encoded with the exception of: @*_+-./
                      //in the back-end, url encode will replace a space with a '+', and it will encode '@' t0 '%40'
                      n[m] = escape(data.split(" ").join("+"))
                        .split("@")
                        .join("%40")
                    } else {
                      n[m] = data
                    }
                    //here is an issue if one of the value is JSON object
                    //no implementation for this issue as it rare to see
                    base_string = base_string + m + "=" + n[m] + "&"
                  }

                  return n
                }, {})
            )

            text.push(JSON.parse(jso))
          }
          r[k] = text
        } else if (typeof currentValue === "boolean") {
          var booleanNo = 0
          if (currentValue) {
            booleanNo = 1
          }
          r[k] = currentValue
          base_string = base_string + k + "=" + booleanNo + "&"
        } else {
          if (typeof currentValue === "string") {
            //The escape function is a property of the global object. Special characters are encoded with the exception of: @*_+-./
            //in the back-end, url encode will replace a space with a '+', and it will encode '@' t0 '%40'
            r[k] = escape(currentValue.split(" ").join("+"))
              .split("@")
              .join("%40")
          } else {
            r[k] = currentValue
          }
          //here is an issue if one of the value is JSON object
          //no implementation for this issue as it rare to see
          base_string = base_string + k + "=" + r[k] + "&"
        }
        return r
      }, {})
  )
}
